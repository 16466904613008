<template>
  <div class="advanceSearchDocList_box">
    <div class="advanceSearchDocList_content row" ref="searchContent">
      <!-- 头部搜索条件 -->
      <div class="advanceSearchVal_box my_card">
        <div class="advanceSearchVal fs16 row_2">
          <span class="SearchVal_title">搜索条件:</span>
          <span class="advanceSearch_item_box" v-for="(item,i) in selectAdvanceSearchList" :key="i">
            <span class="advanceSearch_item" v-show="item.inputVal || item.newTime">
              <span class="option" v-show="i!==0">{{item.option}}</span>
              <span class="key">[ {{item.name}} ]</span>
              <span class="val">
                <template v-if="item.type === 'text'">
                  {{item.inputVal}}
                </template>
                <template v-if="item.type === 'date'">
                  {{item.time.join('至')}}
                </template>
              </span>
            </span>
          </span>
        </div>
        <div @click="goToAdvanceSearchPage" class="updataAdvanceSearchVal_btn my_btn_slide">
          修改条件
        </div>
      </div>
      <!-- 顶部按钮盒子 -->
      <div class="top_btn_box">
        <div class="filter display_flex alignItems_center flex_spaceBetween">
          <div class="img_title">
            <img class="fileter_icon" src="@/assets/img/docuInfo/searchFilter_icon.png" alt="">
            筛选条件
          </div>
          <span class=" hand" @click="onResetFilterSearch">重置筛选</span>
        </div>
        <div class="pagination">
          <span class="pagination_total" v-if="!this.isLoading">共 {{total}} 条</span>
          <el-pagination small background @size-change="onSizeChange"
                         @current-change="onCurrentChange" :current-page="serveConfig.pageNum"
                         :page-sizes="[10, 20]" :page-size="serveConfig.pageSize"
                         layout=" sizes, prev, pager, next, jumper"
                         :total="total > 10000? 10000 : total" :pager-count="11"
                         :hide-on-single-page="true">
          </el-pagination>
        </div>
        <div class="order display_flex_center">
          <span class="key fs16">排序</span>
          <el-select v-model="serveConfig.order" @change="onOrderChange" placeholder="请选择"
                     size="mini">
            <el-option v-for="item in orderList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </div>
        <!-- 全选框 -->
        <div ref="checkAllBox" class="checkout_box my_card display_flex flex_direction"
             v-show="isSelectDocList.length">
          <div class="checkAll_box flex_shrink0">
            <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange"
                         v-model="isSelectAll">全选</el-checkbox>
          </div>
          <div class="select_number_box display_flex">
            <!-- <i>已选: </i> -->
            <span class="selectNum fs20">{{isSelectDocList.length}}</span>
          </div>
          <div class="openExportDialog_btn hand fs14 flex_shrink0" @click="openExportDialog">
            导出
          </div>
          <div v-if="false" class="openExportDialog_btn hand fs14 flex_shrink0">
            分析
          </div>
        </div>
      </div>

      <div class="advanceSearch_content display_flex">
        <!-- 内容盒子 -->
        <div class="filter_search_box flex_shrink0 scrollbar">
          <SearchFilter ref="SearchFilter" :filterConfig="filterConfig"
                        @filterConfigChange="filterConfigChange" :isShowTime="isShowTime" />
        </div>
        <div class="search_doclist" @click="docBoxClick" v-loading="isLoading"
             element-loading-text="正在为您加载……">
          <article-list v-if="!isEmpty" :i="serveConfig.pageSize * (serveConfig.pageNum - 1)"
                        :isRadio="true" :honDocuList="docList" @checkboxChange="onCheckboxChange"
                        :isShowEmpty="false" />
          <WechatCode v-else />
        </div>
      </div>
      <!-- 选中轨迹盒子 -->
      <i class="animation_box" v-if="isShowAnimationBox" ref="animationBox"
         :style="{top:config.y+ 'px', left:config.x+ 'px', transform :`scale(${config.scale})`}"></i>
      <ExportSelectDocFormat ref="ExportSelectDocFormat" :isExportDialog.sync="isExportDialog"
                             :selectDocIdList="isSelectDocList" @emptySelectAll="emptySelectAll"
                             @exportExcelComplete="emptySelectAll" @deleteItem="deleteItem" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getAdvancrSearch, getFilterSearchVal, removeFilterSearchVal, setFilterSearchVal } from '@/utils/storage.js'
import { gainHighSearch } from '@/api/article.js'
import SearchFilter from '@/components/searchFilter/index.vue'
import ExportSelectDocFormat from '@/components/exportSearchDocDialog/index.vue'
import WechatCode from '@c/wechatCode/index.vue'
import store from '@/store/index.js'
import { endTime } from '@/utils/index.js'
export default {
  components: {
    SearchFilter,
    ExportSelectDocFormat,
    WechatCode
  },
  data () {
    return {
      filterConfig: {
        time: [1920, endTime],
        category: [],
        if: [],
        docPublishType: [],
        type: [],
        sex: [],
        age: []
      },
      docList: [],
      orderList: [
        { id: 2, name: '发表日期' },
        { id: 0, name: '相关性' },
        { id: 1, name: 'IF值' },
        { id: 3, name: '被引用' },
        { id: 4, name: '恢复默认' }
      ],
      serveConfig: {
        order: null,
        keywords: '',
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      isSelectDocList: [], // 选中的文献列表
      // 轨迹动画配置
      config: {
        x: 0,
        y: 0,
        scale: 1
      },
      contentBoxLeft: 0, // 内容主题离视口的左边距
      isShowAnimationBox: false, // 选中轨迹盒子装态
      isExportDialog: false, // 导出弹框状态
      isIndeterminate: false, // 半选状态
      isSelectAll: false, // 全选框选中状态
      isEmpty: false,
      isLoading: false
    }
  },
  methods: {
    // 删除某一项
    deleteItem (docId) {
      const i = this.isSelectDocList.indexOf(docId)
      if (i !== -1) this.isSelectDocList.splice(i, 1)
      const item = this.docList.find(item => item.id === docId)
      if (item) item.radio = false
    },
    // 取消所有选中项
    emptySelectAll () {
      this.isSelectDocList = []
      this.docList.forEach(item => {
        item.radio = false
      })
      this.isIndeterminate = false
      this.isSelectAll = false
    },
    // 打开导出弹框
    openExportDialog () {
      if (!this.userId) return this.$login()
      this.isExportDialog = true
      this.$refs.ExportSelectDocFormat.openDialog()
    },
    // 获取鼠标位置
    docBoxClick (e) {
      this.config.x = e.clientX
      this.config.y = e.clientY
      this.config.scale = 1
    },
    // 选中动画
    selectAnimation () {
      this.isShowAnimationBox = true
      this.$nextTick(() => {
        const box = this.$refs.checkAllBox
        this.config.x = box.offsetLeft + this.contentBoxLeft
        this.config.y = document.documentElement.scrollTop > 100 ? 115 : 235 - document.documentElement.scrollTop
        this.config.scale = 0.2
      })
      setTimeout(() => {
        this.isShowAnimationBox = false
      }, 300)
    },
    // 全不选
    onNone () {
      this.docList.forEach(item => {
        item.radio = false
        this.cancelselectItem(item)
      })
      this.checkAllChange()
    },
    // 全选
    onSelectAll () {
      this.docList.forEach(item => {
        if (this.isSelectDocList.length >= 100) return
        item.radio = true
        this.isSelectDocList.push(item.id)
      })
      this.isSelectDocList = [...new Set(this.isSelectDocList)]
      this.checkAllChange()
      if (this.isSelectDocList.length >= 100) this.$myMessage.warning('最多每次可选择100条数据')
    },
    // 全选盒子
    handleCheckAllChange (val) {
      if (val) {
        this.isIndeterminate = false
        this.onSelectAll()
      } else {
        this.onNone()
      }
    },
    // 取消选择某一项
    cancelselectItem (item) {
      const i = this.isSelectDocList.findIndex(selectDocId => selectDocId === item.id)
      if (i !== -1) this.isSelectDocList.splice(i, 1)
    },
    // 切换全选状态
    checkAllChange () {
      const isDocList = this.docList.filter(item => item.radio)
      if (this.isSelectDocList.length > 0) {
        this.isIndeterminate = true
        this.isSelectAll = false
        if (isDocList.length === this.docList.length) {
          this.isIndeterminate = false
          this.isSelectAll = true
        }
      } else {
        this.isIndeterminate = false
        this.isSelectAll = false
      }
    },
    // 复选框更改
    onCheckboxChange (item) {
      if (item.radio) {
        if (this.isSelectDocList.length >= 100) {
          item.radio = false
          return this.$myMessage.warning('最多每次可选择100条数据')
        }
        this.isSelectDocList.push(item.id)
        this.selectAnimation()
      } else {
        this.cancelselectItem(item)
      }
      this.checkAllChange()
    },
    // 获取高级搜索条件字段
    getAdvanceSearchServeVal (advanceSearchList) {
      return advanceSearchList.reduce((str, item) => {
        if (item.type === 'date') {
          str += `@@${item.option}$$${item.enName}${item.newTime}`
        } else {
          str += `@@${item.option}$$${item.enName}$$${item.inputVal}`
        }
        return str
      }, '')
    },
    // 过滤搜索条件改变
    filterConfigChange () {
      setFilterSearchVal(this.filterConfig)
      this.getAdVanceSearchDocList()
    },
    // 重置搜索筛选条件
    onResetFilterSearch () {
      this.filterConfig = this.$options.data().filterConfig
      removeFilterSearchVal()
      this.getAdVanceSearchDocList()
    },
    onOrderChange (newVal) {
      if (newVal === 4) {
        this.serveConfig.order = null
      }
      this.serveConfig.pageNum = 1
      this.getAdVanceSearchDocList()
    },
    onCurrentChange (pageNum) {
      this.serveConfig.pageNum = pageNum
      this.getAdVanceSearchDocList(false)
    },
    onSizeChange (pagesize) {
      this.serveConfig.pageSize = pagesize
      this.serveConfig.pageNum = 1
      this.getAdVanceSearchDocList(false)
    },
    // 获取列表
    getAdVanceSearchDocList (isEmptySelectAll = true) {
      this.isLoading = true
      this.$nextTick(async () => {
        try {
          if (isEmptySelectAll) this.emptySelectAll()
          const advanceSearchVal = this.getAdvanceSearchServeVal(this.selectAdvanceSearchList)
          const searchFilterVal = this.$refs.SearchFilter.getSearchFilterVal()
          this.serveConfig.keywords = advanceSearchVal + searchFilterVal
          this.docList = []
          const data = { ...this.serveConfig }
          if (data.order === null) data.order = 0
          const res = await gainHighSearch(data)
          this.docList = res.data?.map((item) => ({
            ...item,
            radio: this.isSelectDocList.findIndex(selectDocId => selectDocId === item.id) !== -1
          }))
          this.isEmpty = res.data.length === 0
          this.checkAllChange()
          this.total = this.docList[0] ? this.docList[0].totalHits : 0
        } catch (error) {
          this.$message.warning('服务错误')
        } finally {
          this.$nextTick(() => {
            this.isLoading = false
          })
        }
      })
    },
    // 跳转高搜
    goToAdvanceSearchPage () {
      this.$router.push('/advanceSearch')
    }
  },
  computed: {
    ...mapGetters(['selectAdvanceSearchList', 'userId']),
    isShowTime () {
      const dataItem = this.selectAdvanceSearchList.find(item => item.type === 'date')
      return !dataItem
    }
  },
  beforeRouteEnter (to, from, next) {
    const selectAdvanceSearch = getAdvancrSearch().find(item => item.inputVal || item.newTime)
    if (selectAdvanceSearch) {
      next()
    } else {
      next('/advanceSearch')
    }
  },
  created () {
    if (getFilterSearchVal()) {
      this.filterConfig = getFilterSearchVal()
    }
    store.commit('heightSearch/sessionStorageAdvanceSearch')
  },
  mounted () {
    this.getAdVanceSearchDocList()
    const contentBox = this.$refs.searchContent
    this.contentBoxLeft = contentBox.offsetLeft
  }
}
</script>
<style lang="less" scoped>
.advanceSearchDocList_box {
  width: 100%;
  min-height: 700px;
  background-color: #f5f7fb;
  .advanceSearchDocList_content {
    padding-top: 30px;
    min-height: calc(100vh - 251px);
  }
  .advanceSearchVal_box {
    display: grid;
    grid-template-columns: 1fr 110px;
    width: 100%;
    padding: 30px 20px;
    .advanceSearchVal {
      padding-right: 10px;
      color: #4b639f;
      .SearchVal_title {
        margin-right: 10px;
        font-weight: 600;
      }
      .advanceSearch_item_box {
        word-break: break-all;
      }
      .advanceSearch_item {
        .option {
          margin: 0 5px;
          font-size: 15px;
          color: #fa6400;
        }
      }
    }
    .updataAdvanceSearchVal_btn {
      // position: relative;
      width: 110px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      color: #4b639f;
      border: 1px solid #4b639f;
    }
  }

  .top_btn_box {
    position: sticky;
    top: 52px;
    display: grid;
    z-index: 100;
    grid-template-columns: 280px 1fr 145px;
    background-color: #f5f7fb;
    padding: 10px 0;
    .filter {
      padding: 0 10px;
      color: #4b639f;
      .img_title {
        font-weight: 700;
        .fileter_icon {
          width: 20px;
        }
      }
    }
    .pagination {
      padding-left: 20px;
      display: grid;
      grid-template-columns: 90px auto;
      align-items: center;
      .pagination_total {
        color: #606266;
      }
    }
    .order {
      .key {
        color: #4b639f;
        font-weight: 600;
      }
      .el-select {
        margin-left: 10px;
        width: 100px;
        /deep/ .el-input__inner:focus {
          border-color: #4b639f !important;
        }
      }
    }
    .checkout_box {
      position: absolute;
      top: 52px;
      right: -100px;
      width: 80px;
      // height: 100px;
      overflow: hidden;
      .checkAll_box {
        width: 100%;
        height: 35px;
        line-height: 35px;
        text-align: center;
        border-bottom: 1px solid #e8e8e8;
      }
      .select_number_box {
        flex: 1;
        align-items: center;
        justify-content: center;
        .selectNum {
        }
      }
      .openExportDialog_btn {
        width: 100%;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-top: 1px solid #e8e8e8;
        font-weight: 500;
        color: #4b639f;
      }
    }
  }

  .advanceSearch_content {
    .filter_search_box {
      position: sticky;
      top: 104px;
      z-index: 100;
      width: 290px;
      max-height: calc(100vh - 150px);
      margin-right: 10px;
      margin-bottom: 20px;
      overflow: auto;
    }
    .search_doclist {
      flex: 1;
      /deep/ .literature {
        .list:first-child {
          margin-top: 0;
        }
      }
      /deep/.is_blank {
        margin-top: 0 !important;
      }
    }
  }
  .animation_box {
    position: fixed;
    width: 18px;
    height: 18px;
    top: 0;
    left: 0;
    border-radius: 3px;
    background-color: #5cacfb;
    z-index: 100;
    transition: all 0.3s ease-out; //ease-out linear
  }
}
</style>
